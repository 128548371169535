/* .link-item::after{
  content:"";
  position: absolute;
  top: 16%;
  right: 0px;
  height: 70%;
  width: 5px;
  border-radius: 5px;
  background: #F7D372;
  z-index: 1;
  transition: all .9s ease-in-out;
} */

/* .sidebar-menu-list::-webkit-scrollbar {
  width: 0px;
}

.sidebar-menu-list::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  border-radius: 100px;
  background-color: transparent;
  background-clip: content-box;
}

.sidebar-menu-list::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}
.quill .ql-editor {
  font-family: 'Inter';
}
::-webkit-scrollbar {
  width: 0px;
} */

.series-radio .chakra-radio__label{
  width: 100% !important;
}
.codex-editor__redactor{
  margin-top: 20px;
  padding-bottom: 100px !important;
  padding-top: 10px !important;
  border: 2px solid #f4f4f4;
  border-radius: 10px;
}